import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    }, {
        path: '/r/create',
        name: "CreateRecording",
        component: () => import('../views/CreateRecording.vue'),
        alias: '/r/:id?',
        props: true
    }, {
        path: '/r/:id/confirm',
        name: "ConfirmRecording",
        component: () => import('../views/ConfirmRecording.vue'),
        props: true
    }, {
        path: "/",
        name: "ListRecordings",
        component: () => import('../views/ListRecordings.vue'),
        alias: "/r/list",
    }, {
        path: "/account", name: "Account", component: () => import('../views/Account.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router