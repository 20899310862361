<template>
  <Disclosure v-slot="{ open }" as="nav" class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">

          <!-- consakit image -->
          <router-link to="/" class="flex-shrink-0 flex items-center">
            <img alt="Consakit logo" class="block h-12 p-1 w-auto" src="@/assets/consakit-logo.svg" />
          </router-link>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8">

            <router-link v-for="route in navigationItems" v-slot="{ navigate, isActive, href }" :to="route.path" custom>
              <a :class="isActive ? 'border-consakit-blau-1 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
                 :href="href" class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                 @click="navigate">
                {{ route.name }}
              </a>
            </router-link>

          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">

          <!-- desktop Profile -->
          <Menu as="div" class="ml-3 relative h-full">

            <!-- image button -->
            <div class="h-full sm:flex space-x-8">
              <MenuButton v-show="$store.state.user"
                          class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-consakit-blau-1 text-gray-900">
                {{ $store.state.user?.displayName || $store.state.user?.email }}
              </MenuButton>
            </div>

            <!-- dropdown menu -->
            <transition enter-active-class="transition ease-out duration-200" class="z-30"
                        enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">

                <MenuItem v-for="route in profileItems" v-slot="{ active }">
                  <router-link v-if="route.path" v-slot="{ navigate, isActive, href }" :to="route.path" custom>
                    <a :href="href" class="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700" @click="navigate">
                      {{ route.name }}
                    </a>
                  </router-link>
                  <button v-else class="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          @click="route.action">
                    {{ route.name }}
                  </button>
                </MenuItem>

              </MenuItems>
            </transition>

          </Menu>

        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
              class="inline-flex items-center justify-center p-2 rounded text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-consakit-blau-1">
            <span class="sr-only">Menü öffnen</span>
            <MenuIcon v-if="!open" aria-hidden="true" class="block h-6 w-6" />
            <XIcon v-else aria-hidden="true" class="block h-6 w-6" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <!-- desktop popover menu -->
    <DisclosurePanel class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">

        <router-link v-for="route in navigationItems" v-slot="{ navigate, isActive }" :to="route.path" custom>
          <a :class="isActive ? 'border-consakit-blau-1 text-indigo-700' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'"
             :href="href" class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium" @click="navigate">
            {{ route.name }}
          </a>
        </router-link>

      </div>
      <div class="pb-3 border-gray-200" :class="navigationItems || 'border-t pt-4'">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <!--img alt="" class="h-10 w-10 rounded-full"
                 src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" /-->
            <font-awesome-icon :icon="['far', 'user']" size="2x" />
          </div>
          <div class="ml-3"> <!--  class="ml-3" -->
            <div class="text-base font-medium text-gray-800">{{ $store.state.user?.displayName }}</div>
            <div class="text-sm font-medium text-gray-500">{{ $store.state.user?.email }}</div>
          </div>
          <!--button
              class="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-consakit-blau-1"
              type="button">
            <span class="sr-only">View notifications</span>
            <BellIcon aria-hidden="true" class="h-6 w-6" />
          </button-->
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton as="a"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                            href="#">
            Your Profile
          </DisclosureButton>
          <DisclosureButton as="a"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                            href="#">
            Settings
          </DisclosureButton>
          <DisclosureButton as="a"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                            href="#">
            Sign out
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
import { auth } from "@/plugins/firebase/auth";
import { signOut } from "firebase/auth";
import router from "@/router";

const navigationItems = [];

const profileItems = [
  { name: "Account", path: "/account" },
  {
    name: "Ausloggen", action: () => {
      signOut(auth);
      router.push("/login");
    },
  }
]

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
  },
  computed: {
    navigationItems() {
      return this.$store.state.user ? navigationItems : [{ path: "/login", name: "Berater Login" }];
    },
  },
  data() {
    return {
      profileItems
    }
  }
}
</script>