import { initializeApp } from "firebase/app";
import { firebaseConfig } from "@/plugins/firebase/api-key";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const functions = getFunctions(app, "europe-west3");
export const confirmFunction = httpsCallable(functions, "confirmRecording");
export const sendConfirmationMailFunction = httpsCallable(functions, "sendConfirmationMail");

if (process.env.NODE_ENV !== 'development') {
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider("6LcmCx8eAAAAAK8Kjb2yp8fzcWaLku2l8sxyjY68"),
        isTokenAutoRefreshEnabled: true
    });
}

if (process.env.EMULATOR === "true") {
    connectStorageEmulator(storage, "localhost", 9199);
    connectFunctionsEmulator(functions, "localhost", 5001);
}