import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './assets/tailwind.css'
import log from "loglevel";
import { createMetaManager } from 'vue-meta'
import "@/plugins/fontawesome";
import "typeface-roboto";
import "typeface-montserrat";
import "dayjs/locale/de";
import dayjs from "dayjs";

dayjs.locale('de')
log.enableAll();
store.dispatch("initialize").then(() => log.info("Initialized"));

createApp(App).use(store).use(router).use(createMetaManager()).component("font-awesome-icon", FontAwesomeIcon).mount('#app')