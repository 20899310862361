<template>
  <div class="font-montserrat">
    <!-- vue meta -->
    <metainfo>
      <template v-slot:title="{ content }">
        {{ content ? `${content} - consakit` : `consakit` }}
      </template>
    </metainfo>

    <div v-if="$store.state.initialized">
      <navbar />
      <router-view />
    </div>
    <div v-else class="w-screen h-screen flex justify-center items-center">
      <spinner class="text-consakit-blau-1" />
    </div>
  </div>
</template>
<script>
import navbar from "@/components/gui-elements/Navbar.vue";
import { useMeta } from 'vue-meta';
import Spinner from "@/components/gui-elements/Spinner";

export default {
  components: {
    Spinner,
    navbar,
  },
  setup() {
    useMeta({
      title: "Willkommen!",
      titleTemplate: "%s | consakit",
    });
  },
};
</script>
<style lang="scss">
html {
  @apply bg-gray-100;
}
</style>