export const authDomain = "auth.consakit.de";
export const appDomain = "logger.consakit.de";
export const authHost = "https://" + authDomain;
export const appHost = "https://" + appDomain;

export const firebaseConfig = {
    apiKey: "AIzaSyD9gIhGMekdhwB-pzBKrLdSx2068RyCBX4",
    authDomain,
    projectId: "consakit",
    storageBucket: "consakit.appspot.com",
    messagingSenderId: "323720294268",
    appId: "1:323720294268:web:1294a3bb6baaaa2ff8bef9"
};