import { createStore } from 'vuex'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/plugins/firebase/auth";
import { db, plansCollectionName } from "@/plugins/firebase/db";
import { doc, getDoc } from "firebase/firestore";
import router from "@/router";

export const PLAN_TYPES = {
    ADMIN: "Admin",
    PAYED: "Bezahlt",
    FREE: "Kostenlos",
}


// todo: Tastaturzentrierte eingabe

export default createStore({
    state: {
        initialized: false,
        user: null,
        plan: {
            type: PLAN_TYPES.FREE,
        }
    },
    mutations: {
        setUser: (state, user) => state.user = user,
        setInitialized: (state, initialized) => state.initialized = initialized,
        setPlan: (state, plan) => state.plan = plan
    },
    actions: {
        initialize(store) {
            onAuthStateChanged(auth, async (user) => {
                store.commit("setUser", user)

                if (user) {
                    console.log(user.uid)
                    const docSnap = await getDoc(doc(db, plansCollectionName, user.uid));

                    if (docSnap.exists())
                        store.commit("setPlan", docSnap.data());
                }

                store.commit("setInitialized", true)
            });
        }
    }
    ,
    modules: {}
})