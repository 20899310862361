import "@/plugins/firebase/initialize";
import { appHost, authDomain } from "@/plugins/firebase/api-key";
import {
    getAuth,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPopup,
    connectAuthEmulator
} from "firebase/auth";

export const auth = getAuth();
export const googleAuthProvider = new GoogleAuthProvider();

if (process.env.EMULATOR === "true")
    connectAuthEmulator(auth, "http://localhost:9099");

auth.useDeviceLanguage();
checkSignInWithEmailLink();

export function startGoogleSignInFlow() {
    signInWithPopup(auth, googleAuthProvider).catch((error) => {
        // ToDo: handle error
    });
}

export function checkSignInWithEmailLink() {
    if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');

        if (!email)
            email = window.prompt("Bitte gib deine E-Mail erneut ein"); // todo: gui

        if (!email)
            alert("Login fehlgeschlagen"); // todo: gui

        signInWithEmailLink(auth, email, window.location.href)
            .then(() => window.localStorage.removeItem('emailForSignIn'))
            .catch((error) => {
                // todo: handle errors, especially timeout or wrong mail
            });
    }
}

export function startMailSignInFlow(email) {
    if (!email)
        throw Error("Email is required");

    const actionCodeSettings = {
        url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : appHost) + "/login?email",
        handleCodeInApp: true,
        // iOS: { bundleId: 'com.example.ios' },
        // android: { packageName: 'com.example.android', installApp: true, minimumVersion: '12' },
        dynamicLinkDomain: authDomain
    };

    return new Promise((resolve, reject) => {
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally, so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                resolve();
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // todo: handle error
                reject();
            });
    });
}