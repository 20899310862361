import "@/plugins/firebase/initialize";
import { addDoc, collection, doc, getFirestore, onSnapshot, serverTimestamp, connectFirestoreEmulator } from "firebase/firestore";
import store from "@/store";

export const db = getFirestore();
export const plansCollectionName = "account-plans";
export const recordingsCollection = collection(db, "recordings");
export const usersCollection = collection(db, "users");

if (process.env.EMULATOR === "true")
    connectFirestoreEmulator(db, 'localhost', 8080);

export async function createRecording() {
    const r = await addDoc(recordingsCollection, {
        createdAt: serverTimestamp(),
        products: [],
        status: 0,
        uid: store.state.user.uid
    });
    return {
        createdAt: serverTimestamp(),
        files: [],
        products: [],
        status: 0,
        uid: store.state.user.uid,
        id: r.id
    }
}

export function subscribeRecording(id, callback) {
    onSnapshot(doc(recordingsCollection, id), snapshot => {
        callback(snapshot.exists() ? { ...snapshot.data(), id: snapshot.id } : null);
    });
}